<template>
  <article>
    <div class="hero">
      <h1>COVID-19</h1>
      <p class="subh">
        Conheça os projetos de combate à pandemia geridos pela AHBB|Rede Santa Casa
      </p>
    </div>
    <section class="covid-19">
      <h3>
        Transparência
        <p class="subh">
          Acesse os documentos dos nossos projetos Covid-19
        </p>
      </h3>
      <banner-covid-19-transparencia />
    </section>
    <section id="numeros">
      <div>
        <h2 class="title">
          Números em Ação
        </h2>
        <div class="texto-numeros">
          <p>
            Desde março de 2020, a AHBB ajudou a implementar mais de 350 novos leitos dedicados ao combate à COVID-19. Centenas de profissionais da saúde dedicados a esta causa fazem parte destes projetos. Veja abaixo alguns números que dão a dimensão do nosso trabalho neste período:
          </p>
        </div>

        <img src="../assets/img-numeros_v2.png" class="img-numeros-desk">
        <img src="../assets/img-numerosmobile_v3.png" class="img-numeros-mobile">

        <div class="img-gestao">
          <h2>Uma gestão séria, profissional, experiente e transparente, com total zelo e respeito às pessoas, faz toda a diferença neste momento.</h2>
        </div>

        <div class="texto-numeros">
          <p>
            A missão da AHBB é zelar pela vida de cada brasileiro e transformar todos o locais em que atuamos em referência de saúde. Durante a pandemia de COVID-19, mais do que nunca, colocamos em prática esta vocação.
          </p>
          <p>
            É claro que, sem o comprometimento e o sacrifício de nossos colaboradores e parceiros neste período, seria impossível manter a qualidade e a seriedade de nosso trabalho, como instituição.
          </p>
          <p>
            Fomos ainda mais estimulados a buscar por instrumentos, tecnologias e modelos de administração que levem racionalidade à gestão de saúde no Brasil. Trabalhamos para garantir a eficiência dos serviços que gerimos, usando informação e inteligência. Criamos este site inspirados por esta responsabilidade.
          </p>
          <p>
            As informações aqui reunidas são a forma que encontramos de demonstrar a todos, colaboradores e sociedade, a dimensão do nosso trabalho durante a pior pandemia a acometer a humanidade em mais de um século.
          </p>
        </div>

        <div class="doutor-antonio">
          <div class="row">
            <div class="img-doutor">
              <img src="../assets/img-dr-antonio.png">
            </div>
            <div class="assinatura-doutor">
              <div class="assinatura">
                <img src="../assets/img-assinatura.png">
                <p>
                  Lins, 18 de Junho de 2020<br>
                  Dr. Antonio Carlos Pinotti<br>
                  Presidente - AHBB | Rede Santa Casa
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="ficha-tecnica">
      <div class="title-mapa">
        <h4>PROJETOS COVID-19 AHBB | REDE SANTA CASA</h4>
        <h1>CLIQUE NA CIDADE E CONHEÇA A FICHA TÉCNICA</h1>
      </div>

      <div class="mapa-cidades">
        <div
          @click="clicouEmCidade($event)"
          v-for="cidade in cidades"
          :key="cidade.id"
          class="pointer"
          :class="cidade.lado"
          :style="`top: ${cidade.top}; left: ${cidade.left};`"
          :data-cidade="cidade.id"
        >
          <div class="nome-cidade">
            {{ cidade.cidade }}
          </div>
        </div>
      </div>
    </section>

    <div
      class="light-box-tabela"
      :style="infoCidadeClicada ? 'display:block;' : null"
    >
      <div class="container-tabela">
        <div
          v-for="info in dadosCidade"
          :key="info.nome"
          class="tabela-dados"
        >
          <table cellspacing="0" cellpadding="0">
            <tbody v-if="info.nome">
              <tr class="c-cidade" v-if="info.cidade">
                <th class="cidade">
                  {{ info.cidade }}
                </th>
              </tr>

              <tr>
                <th class="fontS">
                  {{ info.nome }}
                </th>
              </tr>

              <tr class="c-01" v-if="info.gestao">
                <th class="gestao">
                  {{ info.gestao }}
                </th>
              </tr>

              <table>
                <tr class="c-01" v-if="info.leitos_enfermaria">
                  <td>
                    Enfermaria COVID-19
                  </td>
                  <td>
                    {{ info.leitos_enfermaria }}
                  </td>
                </tr>

                <tr class="c-01" v-if="info.suporte_covid_19">
                  <td>
                    Suporte COVID-19
                  </td>
                  <td>
                    {{ info.suporte_covid_19 }}
                  </td>
                </tr>

                <tr class="c-01" v-if="info.estabilizacao_covid_19">
                  <td>
                    Estabilização COVID-19
                  </td>
                  <td>
                    {{ info.estabilizacao_covid_19 }}
                  </td>
                </tr>

                <tr class="c-01" v-if="info.semi_covid_19">
                  <td>
                    Semi COVID-19
                  </td>
                  <td>
                    {{ info.semi_covid_19 }}
                  </td>
                </tr>

                <tr class="c-01" v-if="info.uti_covid_19">
                  <td>
                    UTI COVID-19
                  </td>
                  <td>
                    {{ info.uti_covid_19 }}
                  </td>
                </tr>

                <tr class="c-02" v-if="info.municipios_referenciados">
                  <td>
                    Municípios Referenciados
                  </td>
                  <td>
                    {{ info.municipios_referenciados }}
                  </td>
                </tr>

                <tr class="c-02" v-if="info.populacao_intorno">
                  <td class="radius">
                    População no entorno
                  </td>
                  <td class="radius">
                    {{ info.populacao_intorno }}
                  </td>
                </tr>
              </table>
            </tbody>
          </table>
          <button @click="cidadeClicada" class="fechar-tabela">
            X
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>

section {
  margin: 0px 0;
}

h3 {
  text-align: center;
}

p.subh {
  text-align: center;
  margin-top: 8px;
}

h1 {
  text-transform: unset;
}

h4 {
  margin: 0;
}

h5 {
  margin: 10px 0px 0px 0px;
}

  .hero {
    min-height: 445px;
    padding-top: 100px;
    padding-bottom: 100px;

    background-image: url('../assets/CARDCOVID.jpg');
    background-image: image-set('../assets/CARDCOVID.jpg' 1x, '../assets/CARDCOVID@2x.jpg' 2x);

    p.subh {
      font-size: 40px;
      font-weight: 300;
    }
  }

/* NUMEROS EM ACAO */
#numeros {
  background: #efefef;
  text-align: center;
  padding: 50px 150px;
  margin-top: 48px;

  .title {
    color: #83b76a;
    font-weight: bold;
    text-transform: uppercase;
  }

  img {
    width: 100%;
  }
}

.img-numeros-desk {
  display: block;
}

.img-numeros-mobile {
  display: none;
}

.img-gestao {
  background-image: url(../assets/img_gestao.png);
  background-size: cover;
  text-align: center;
  border-radius: 30px;
  margin: 48px 0px;
  min-height: 500px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    left: 50%;
    transform: translateX(-50%);
  }

  h2 {
    color: #fff;
    font-weight: 200;
    position: absolute;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.texto-numeros {
  color: #31425f;

  p {
    color: #31425f;
    font-size: 16px;
    font-weight: 300;
    margin: 20px 0px;
    text-align: center !important;
  }
}

.doutor-antonio {
  padding: 28px 0px;

  .row{
    margin: 0px 0px;
  }
}

.img-doutor {
  width: 165px;
  float: left;
  margin-right: 20px;
}

.assinatura-doutor {
  text-align: left;
}

.assinatura {
  img {
    width: 300px !important;
  }

  p {
    color: #83b76a;
    font-size: 16px;
    font-weight: 300;
  }
}

/* MAPA */
#ficha-tecnica {
  background-color: rgb(41,59,89);
  background-color: linear-gradient(260deg, rgba(41,59,89,1) 63%, rgba(22,31,47,1) 100%);
  overflow: hidden;
  min-height: 1000px;
  height: 100%;
  position: relative;
  padding-top: 800px;
  width: 100%;
}

.mapa-cidades {
  background-image: url(../assets/mapa-estados.png);
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 1600px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);
}

.title-mapa {
  color: #fff;
  text-align: center;
  display: table;
  text-transform: uppercase;
  margin: auto;
  z-index: 1;
  width: 100%;
  position: absolute;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 1;
  top: 0;

  &::before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: -1;
  }

  h1 {
    border-top: solid 1px #fff;
    padding: 15px 40px;
    display: inline-flex;
    font-weight: bold;
    color: #fff;
    font-size: 2.2rem;
  }

  h4 {
    font-weight: bold;
    margin-bottom: -25px;
    font-size: 1.5rem;
  }
}

.pointer {
  position: absolute;
  top: 50%;
  left: 48%;

  &::after {
    content: '';
    background-image: url(../assets/point.png);
    transition: all 0.2s;
    /* padding: 15px; */
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: cover;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    left: -35px;
    top: 8px;
  }

  &:hover {
    .nome-cidade {
      background: #fff;
      color: #293b59;
    }
  }
}

.pointer-right::after {
  right: -35px;
  left: unset !important;
}

.pointer-select .nome-cidade{
  background: #fff !important;
  color: #293b59 !important;
}

.nome-cidade {
  background: #293b59;
  padding: 10px 20px;
  color: #fff;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 19px;
  border-radius: 5px;
}

.light-box-tabela {
  width: 100%;
  min-height: 100%;
  padding: 40px 15px;
  position: relative;
  display: none;
  background:  #00000078;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  left: 0;
}

.container-tabela {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  max-width: 900px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.tabela-dados {
  min-width: 400px;
  margin: 10px auto;
  margin-bottom: 10px;
  padding: 5px;
  transition: all 0.2s;
  animation: fade-in 0.7s;

  th {
    font-size: 24px;
    text-transform: uppercase;
    max-width: 400px;
  }

  td {
    font-size: 18px;
  }

  table {
    width: 100%;
  }

  .c-cidade {
    border-top: 1px solid #293b59 !important;
    border-bottom: 1px solid #293b59 !important;
  }

  .fechar-tabela {
    background: unset;
    border-radius: 30px;
    padding: 15px 10px;
    letter-spacing: 0;
    line-height: 0;
    border: 1px solid #fff;
    font-weight: bold;
    position: absolute;
    right: -45px;
    top: 15px;
    color: #fff;
    transition: all 0.2s;
  }
}

.tabela-dados table{
  border-radius: 16px;
}

.cidade{
  border-radius: 16px;
  padding: 16px 20px 0;
}

.fontS{
  font-weight: 500;
  padding: 6px 20px 14px;
}

.gestao{
  padding: 10px 20px;
}

.radius{
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.tabela-dados table,
th, td {
  background: #fff;
}

.tabela-dados td {
  background: #fff;
  color: #293b59;
  font-weight: 600;
  padding: 12px 20px;
}

.tabela-dados .c-01 td,
.c-01 th {
  background: #e7e6e6 !important;
}

.tabela-dados .c-02 td,
.c-02 th {
  background: #f2f2f2 !important;
}

.tabela-dados .fechar-tabela:hover {
  background: #fff;
  color: #293b59;
}

.tabela-dados .fechar-tabela:focus{
  outline-color: unset !important;
  outline-width: 0px;
}

.tabela-dados > .row {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 1000px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.seta-esquerda {
  margin-left: 100px;
  color: #293b59;  // Mudamos a cor da seta.
}

.seta-direita {
  margin-right: 100px;
  color: #293b59;  // Mudamos a cor da seta.
}

/*
 * '/¯¯¯¯/|¯¯¯¯|\¯¯¯¯\'  /¯¯¯¯/|¯¯¯¯|'       |¯¯¯¯|  |¯¯¯¯|   /¯¯¯¯/'
 * |    | |    | |    | '|   |/_/         |    |  |¯¯¯¯|  /_/|¯¯¯¯|
 * |    | |    | |    |  \__\/¯¯¯¯/  /¯¯¯¯/|    |  |    |  /¯¯¯¯/|    |
 * |_| |_| |_|       /_/   \__\|_|  |_|  \__\|__|
 *
 */

@media only screen and (max-width: 1200px) {

  .logo {
    width: 180px;
    transition: all 0.2s;
  }

  .col-menu {
    padding-top: 10px;
  }

  .shrink .logo {
    width: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .container-banner {
    .slide-info-box {
      word-wrap: break-word;
      padding: 13px;
      width: 35%;
      min-height: 342px;
      height: fit-content;

      h1 {
        font-size: 1.6rem;
      }

      p, h4 {
        font-size: 1rem;
      }
    }
  }

  .seta-esquerda {
    margin-left: 15px;
  }

  .seta-direita {
    margin-right: 15px;
  }

  .logo {
    width: 200px;
    transition: all 0.2s;
  }

  .shrink .logo {
    width: 200px;
  }

  #menu-toggle {
    display: block;
  }

  .col-menu {
    display: none !important;
  }

  #header {
    padding: 25px 0px;
  }
}

@media only screen and (max-width: 991px) {

  .title-mapa {
    padding-top: 20px;

    h1 {
      font-size: 20px;
      padding: 15px 0px !important;
    }

    h4 {
      font-size: 24px;
      padding: 15px 0px !important;
    }
  }

  .slide-imagem {
    right: 105px;
  }

  .tabela-dados {
    min-width: 360px;
    margin: 10px auto;
    margin-bottom: 10px;
    padding: 5px;

    th {
      font-size: 18px;
    }

    td, th {
      padding: 3px 15px;
      font-size: 14px;
    }

    .fechar-tabela {
      right: 0px;
      top: -35px;
    }
  }

  .container-tabela {
    display: block;
  }

  .img-numeros-desk {
    display: none;
  }

  .img-numeros-mobile {
    display: block;
  }

  .slide-imagem {
    height: 50%;
    width: 45%;
  }

  .slide-info-box h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 768px) {
  #numeros {
    padding: 50px 18px 0px 18px;

    // .img-numeros-mobile {
    //   margin-top: 40px;
    //   transform: scale(1.05);
    // }
  }

  .container {
    display: flex;
    flex-direction: column;
  }
  .container-banner {
    flex-direction: column;

    .slide-imagem {
      top: 5%;
      bottom: 0;
      width: 80%;
      min-height: 200px;
      background-size: cover;
      right: unset;
    }

    .slide-info-box {
      word-wrap: break-word;
      padding: 13px;
      width: 65%;
      top: 120px;
      bottom: 0;
      left: unset;
      min-height: 342px;
      height: fit-content;

      h1 {
        font-size: 1.5rem;
      }

      p, h4 {
        font-size: 1rem;
      }
    }
  }

  .seta-esquerda {
    margin-left: 15px;
  }

  .seta-direita {
    margin-right: 15px;
  }

  .img-gestao {
    min-height: 350px;

    h2 {
      font-size: 24px;
      margin-top: 0px;
    }
  }
  .assinatura {
    img {
        width: 200px !important;
    }
  }
}

@media only screen and (max-width: 425px) {
  .tabela-dados {
    min-width: 250px;
    margin: 10px auto;
    margin-bottom: 10px;
    padding: 5px;
  }

  .container-tabela {
    display: block;
    max-width: 70%;

  }

  .tabela-dados th {
    font-size: 14px;
    padding: 4px 8px;
  }

  .tabela-dados td, th {
    padding: 3px 8px;
    font-size: 10px;
  }

  .tabela-dados .fechar-tabela {
    right: 0px;
    top: -35px;
  }

  .nome-cidade {
    font-size: 15px;
  }
}
</style>

<script>
import BannerCovid19Transparencia from '../components/Covid-19/BannerCovidTransparencia.vue'

export default {
  components: {
    BannerCovid19Transparencia,
  },
  data() {
    return {
      infoCidadeClicada: false,
      cidades: [
        {
          id: 'sao-paulo',
          nome: 'Taipas',
          cidade: 'São Paulo',
          top: '74%',
          left: '51%',
          lado: 'pointer-right',
        },
        {
          id: 'brasilia',
          nome: 'Estádio Nacioal',
          cidade: 'Brasília/DF',
          top: '24%',
          left: '50%',
          lado: 'pointer-right',
        },
        {
          id: 'penapolis',
          nome: 'Santa Casa',
          cidade: 'Penápolis',
          top: '59%',
          left: '52%',
          lado: '',
        },
        {
          id: 'garca',
          nome: 'Santa Casa - Garça',
          cidade: 'Garça',
          top: '67%',
          left: '54%',
          lado: '',
        },
        {
          id: 'bilac',
          nome: 'Santa Casa - Garça',
          cidade: 'Bilac',
          top: '61%',
          left: '42%',
          lado: 'pointer-right',
        },
        {
          id: 'paranaiba',
          nome: 'Paranaíba',
          cidade: 'Paranaíba',
          top: '49%',
          left: '46%',
          lado: '',
        },
      ],
      infosCidades: [
        {
          id: 'sao-paulo',
          nome: 'HOSPITAL GERAL DE TAIPAS',
          cidade: 'São Paulo',
          gestao: 'GESTÃO 28 LEITOS',
          leitos_enfermaria: '',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '18 leitos',
          uti_covid_19: '10 leitos',
          municipios_referenciados: '1',
          populacao_intorno: '600 mil',
        },
        {
          id: 'sao-paulo',
          nome: 'HOSPITAL DA VILA PENTEADO',
          cidade: 'São Paulo',
          gestao: 'GESTÃO 35 LEITOS',
          leitos_enfermaria: '',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '',
          uti_covid_19: '35 leitos',
          municipios_referenciados: '1',
          populacao_intorno: '407 mil',
        },
        {
          id: 'brasilia',
          nome: 'ESTÁDIO NACIONAL DE BRASÍLIA MANÉ GARRINCHA (ENCERRADO)',
          cidade: 'Brasília/DF',
          gestao: 'CONSULTORIA  197 LEITOS',
          leitos_enfermaria: '173 leitos',
          suporte_covid_19: '20 leitos',
          estabilizacao_covid_19: '4 leitos',
          semi_covid_19: '',
          uti_covid_19: '',
          municipios_referenciados: '1',
          populacao_intorno: '3 milhões',
        },
        {
          id: 'bilac',
          nome: 'HOSPITAL BENEFICENTE PADRE VICENTE BERNARDO BRAAKIUS',
          cidade: 'Bilac',
          gestao: 'GESTÃO 8 LEITOS',
          leitos_enfermaria: '8 leitos',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '',
          uti_covid_19: '',
          municipios_referenciados: '3',
          populacao_intorno: '14 mil',
        },
        {
          id: 'penapolis',
          nome: 'CENTRO DE REFERÊNCIA COVID-19 (ENCERRADO)',
          cidade: 'Penápolis',
          gestao: 'GESTÃO 30 LEITOS',
          leitos_enfermaria: '',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '',
          uti_covid_19: '',
          municipios_referenciados: '7',
          populacao_intorno: '91 mil',
        },
        {
          id: 'penapolis',
          nome: 'SANTA CASA DE PENÁPOLIS',
          cidade: 'Penápolis',
          gestao: 'GESTÃO 41 LEITOS',
          leitos_enfermaria: '31 leitos',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '',
          uti_covid_19: '10 leitos',
          municipios_referenciados: '7',
          populacao_intorno: '91 mil',
        },
        {
          id: 'garca',
          nome: 'HOSPITAL SÃO LUCAS',
          cidade: 'Garça',
          gestao: '',
          leitos_enfermaria: '7 leitos',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '',
          uti_covid_19: '',
          municipios_referenciados: '5',
          populacao_intorno: '64 mil',
        },
        {
          id: 'paranaiba',
          nome: 'SANTA CASA DE PARANAÍBA',
          cidade: 'PARANAÍBA',
          gestao: 'GESTÃO 10 LEITOS',
          leitos_enfermaria: '',
          suporte_covid_19: '',
          estabilizacao_covid_19: '',
          semi_covid_19: '',
          uti_covid_19: '10 leitos',
          municipios_referenciados: '4',
          populacao_intorno: '89 mil',
        },
      ],
      dadosCidade: [],
    }
  },
  methods: {
    cidadeClicada() {
      this.infoCidadeClicada = !this.infoCidadeClicada
    },
    clicouEmCidade(event) {
      const nomeCidadeEscolhida = event.target.innerText

      this.dadosCidade = this.infosCidades.filter(info =>
        info.cidade.toLowerCase() === nomeCidadeEscolhida.toLowerCase(),
      )

      this.cidadeClicada()
    },
  },
  metaInfo: {
    title: 'Covid-19',
  },
}
</script>
